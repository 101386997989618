<nav
  id="sidenav"
  class="cmx-navbar cmx-navbar-static-side"
  [class.collapsed]="isCollapsed"
  [class.entity-selector-open]="hiddenLegalEntity"
  [dir]="rtl ? 'rtl' : 'ltr'"
  *ngIf="version === 2"
>
  <div class="cmx-navbar__trigger" (click)="toggleSidebar()" id="cmx-sidebar-toggle">
    <span class="cmx-icon-left-double font-true-white" aria-hidden="true"></span>
    <span class="sr-only">Toggle Sidebar</span>
  </div>

  <ul
    *ngIf="!hideUserPanel"
    class="cmx-navbar__menu single-tone mobile-only user-panel"
    [class.notification-panel]="_notificationItems.length"
  >
    <li class="cmx-navbar__menu-item" id="cmx-sidebar-profile" (click)="goToProfile()">
      <div class="cmx-navbar__menu-link primary">
        <span class="cmx-navbar__menu-icon">
          <div class="cmx-main-nav__icon cmx-main-nav__icon--inverted">{{ initials }}</div>
        </span>
        <span class="cmx-navbar__menu-label">{{ _fullNameUser }}</span>
      </div>
    </li>
    <li class="cmx-navbar__menu-item" (click)="toggleAppNavigation()">
      <div class="cmx-navbar__menu-link primary">
        <span class="cmx-navbar__menu-icon">
          <span class="cmx-main-nav__icon cmx-icon-modules-internal-consoles"></span>
        </span>
        <span class="cmx-navbar__menu-label">CEMEX Apps</span>
      </div>
    </li>
    <li class="cmx-navbar__menu-item" *ngIf="_notificationItems.length">
      <div
        class="cmx-navbar__menu-link primary popout__toggle"
        data-popout-target="#notificationsPopout"
        onclick="toggleSidebar()"
      >
        <span class="cmx-navbar__menu-icon">
          <div class="cmx-main-nav__icon cmx-main-nav__icon--alert">99</div>
        </span>
        <span class="cmx-navbar__menu-label">Notifications</span>
      </div>
    </li>
  </ul>

  <div class="cmx-navbar__legal-entity" [class.disabled]="!legalEntityMenuEnabled">
    <div class="cmx-navbar__legal-entity-wrapper" role="button" (click)="legalEntityMenuEnabled && showLegalEntities()">
      <div class="cmx-navbar__legal-entity-name" id="legalEntityName">
        {{ normalizeString(_currentLegalEntity?.legalEntityDesc) }}
      </div>
      <div class="cmx-navbar__legal-entity-id">
        <span id="legalEntityId">#{{ _currentLegalEntity?.legalEntityTypeCode }}</span>
        <span class="cmx-navbar__legal-entity-toggle cmx-icon-right-single font-true-white" aria-hidden="true"></span>
        <span class="sr-only">Toggle Legal Entity Selector</span>
      </div>
    </div>

    <!-- LEGAL ENTITIES SEARCH / LIST-->
    <div class="cmx-navbar__legal-entity-listing" [class.opened]="hiddenLegalEntity">
      <div class="cmx-navbar__legal-entity-filtering">
        <h2 class="cmx-navbar__legal-entity-filtering-header mobile-only">Customer Selection</h2>
        <label
          for="legal-entity-filtering-input"
          class="cmx-icon-magnifier-glass cmx-navbar__legal-entity-filtering-icon"
        ></label>
        <input
          class="cmx-navbar__legal-entity-filtering-input desktop-only"
          [(ngModel)]="_searchLegalEntity"
          (ngModelChange)="filterLegalEntities()"
          type="text"
          [placeholder]="translationService.pt('views.sidebar.search')"
          name="legal-entity-filtering-input"
        />
      </div>
      <div class="cmx-navbar__legal-entity-items">
        <div
          class="cmx-navbar__legal-entity-item"
          [class.active]="isActiveLegalEntity(legalEntity)"
          *ngFor="let legalEntity of _legalEntityList | filterLegalEntity: _searchLegalEntity"
          (click)="clickLegalEntity(legalEntity)"
        >
          <div class="cmx-navbar__legal-entity-button" role="button">
            <div class="cmx-navbar__legal-entity-name">
              {{ legalEntity?.legalEntityDesc }}
            </div>
            <div class="cmx-navbar__legal-entity-id">#{{ legalEntity.legalEntityTypeCode }}</div>
          </div>
        </div>
      </div>
      <div class="cmx-navbar__legal-entity-footer mobile-only">
        <button class="cmx-button cmx-button--support ripple" onclick="toggleEntitySelector()">Cancel</button>
        <button class="cmx-button ripple" onclick="toggleEntitySelector()">Apply</button>
      </div>
    </div>
    <!-- end .legal-entity-listing -->
  </div>
  <!-- end .legal-entity -->

  <ul class="cmx-navbar__menu" *ngIf="!hiddenLegalEntity">
    <li
      class="cmx-navbar__menu-item"
      *ngFor="let item of _menuItems"
      [ngClass]="{ active: _clickedApp === item, 'collapsed-hover': _isCollapsed }"
    >
      <a
        [id]="'cmx-sidebar-app-' + item?.applicationTitle"
        class="cmx-navbar__menu-link primary"
        data-id="cmx-sidebar-app-{{ item?.applicationTitle }}"
        (click)="onApplicationItemClick(item)"
        [class.active]="shouldDisplayActiveMenu(item, _clickedApp === item)"
      >
        <span
          class="cmx-navbar__menu-icon"
          [ngClass]="setCmxIcons(item?.applicationIcon)"
          *ngIf="!item?.applicationCustomIcon"
        ></span>
        <span class="cmx-navbar__menu-icon" *ngIf="item?.applicationCustomIcon">
          <svg class="custom-icon">
            <use [attr.xlink:href]="'#' + item?.applicationIcon" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
          </svg>
        </span>
        <span class="cmx-navbar__menu-label" [class.collapse]="_isCollapsed">
          <div [attr.position]="rtl ? 'right' : 'left'" diffYLeft="6">
            <div
              class="cmx-navbar__menu-title"
              [innerHTML]="
                translationService.pt('views.sidebar.' + item?.applicationTitle) | replaceString: '-':'-<br>'
              "
            ></div>
          </div>
        </span>
      </a>
      <ul
        class="cmx-navbar__menu-sub"
        *ngIf="item.menu?.menuItems?.length"
        [class.showSubItems]="shouldDisplayActiveMenu(item, _clickedApp === item)"
      >
        <li
          class="cmx-navbar__menu-sub-item"
          *ngFor="let subitem of item?.menu?.menuItems"
          [class.active]="shouldDisplayActiveMenu(subitem, _clickedLink === subitem)"
        >
          <a
            href="javascript:void(0)"
            class="cmx-navbar__menu-sub-link"
            data-id="cmx-sidebar-app-subitem-{{ subitem.menuTitle }}"
            (click)="onSubItemClick(subitem)"
          >
            <div
              class="sub-menu-title"
              [attr.position]="rtl ? 'right' : 'left'"
              cmx-tooltip="{{ translationService.pt('views.sidebar.' + subitem?.menuTitle) }}"
            >
              <div
                class="cmx-navbar__menu-title"
                [innerHTML]="translationService.pt('views.sidebar.' + subitem?.menuTitle) | replaceString: '-':'-<br>'"
              ></div>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>

  <hr class="cmx-navbar__separator mobile-only" />

  <!-- footer options-->
  <ul class="cmx-navbar__menu single-tone mobile-only footer">
    <li class="cmx-navbar__menu-item">
      <button class="cmx-navbar__menu-link primary" id="btn-mobile-lang" (click)="goToSettings()">
        <span class="cmx-navbar__menu-icon"> <span class="cmx-icon-language" aria-hidden="true"></span> </span>
        <span class="cmx-navbar__menu-label">{{ translationService.pt('views.sidebar.language') }}</span>
      </button>
    </li>
    <li class="cmx-navbar__menu-item" (click)="legalClick()">
      <button class="cmx-navbar__menu-link primary">
        <span class="cmx-navbar__menu-icon"> <span class="cmx-icon-document" aria-hidden="true"></span> </span>
        <span class="cmx-navbar__menu-label">{{ translationService.pt('views.sidebar.legal') }}</span>
      </button>
    </li>
    <li class="cmx-navbar__menu-item" (click)="privacyClick()">
      <button class="cmx-navbar__menu-link primary">
        <span class="cmx-navbar__menu-icon"> <span class="cmx-icon-support-center" aria-hidden="true"></span> </span>
        <span class="cmx-navbar__menu-label">{{ translationService.pt('app.footer.privacy') }}</span>
      </button>
    </li>
    <li class="cmx-navbar__menu-item">
      <button class="cmx-navbar__menu-link primary" (click)="signOut()" id="cmx-sidebar-logout">
        <span class="cmx-navbar__menu-icon"> <span class="cmx-icon-logout" aria-hidden="true"></span> </span>
        <span class="cmx-navbar__menu-label">{{ translationService.pt('views.sidebar.logout') }}</span>
      </button>
    </li>
  </ul>
  <!--end footer options-->

  <hr class="cmx-navbar__separator mobile-only" />

  <!--copyright-->
  <div class="cmx-navbar__text mobile-only" (click)="copyrightClick()">
    <span *ngIf="isGetCopyright; else copyrightTranslations" [innerHTML]="lblCopyright"> </span>
    <ng-template #copyrightTranslations>
      <span>
        {{ translationService.pt('views.footer.copyright') }}
      </span>
    </ng-template>
  </div>
  <!--end copyright-->
</nav>

<!--NEW WEB COMPONENT SIDENAV-->
<cwc-sidenav
  id="cwc-sidenav-component"
  class="cmx-nav-3"
  [ngClass]="{ 'sidenav--with-modal': modalOpened }"
  slot="sidenav"
  *ngIf="version === 3"
  [open]="!isCollapsed"
  [dir]="rtl ? 'rtl' : 'ltr'"
  [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
  [enableEventListenerToggle]="enableEventListenerToggle"
>
  <cwc-entity-selector
    id="entitySelector"
    slot="legal-entity-selection"
    *ngIf="legalEntityMenuEnabled"
    (cwcEntityChanged)="setLegalEntityByComponent($event)"
    [collapsed]="isCollapsed"
    [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
    [modalLabelApply]="translationService.pt('views.sidebar.language.apply')"
    [modalLabelCancel]="translationService.pt('views.sidebar.language.cancel')"
    [modalLabelChoose]="translationService.pt('views.sidebar.entity-modal.choose')"
    [modalLabelEmptyHeader]="translationService.pt('views.sidebar.entity-modal.empty-header')"
    [modalLabelEmptyMessage]="translationService.pt('views.sidebar.entity-modal.empty-message')"
    [modalLabelFilter]="
      !!_userProfile && _userProfile.userType !== 'C'
        ? translationService.pt('views.sidebar.customer.search')
        : translationService.pt('views.sidebar.entity-modal.filter')
    "
    [modalTitle]="translationService.pt('views.global.lbl_legalEntity')"
    (cwcModalOpened)="onModalOpened($event)"
    [class]="!modalOpened ? 'collapsable' : 'noCollapsable'"
    [style.pointer-events]="noEvents ? 'none' : 'unset'"
    [ngClass]="{
      displayFlex: isCollapsed
    }"
    [disableFilter]="!!_userProfile && _userProfile.userType !== 'C'"
    [employeeVariant]="!!_userProfile && _userProfile.userType !== 'C'"
    (cwcInputChange)="filterLegalEntities($event)"
    [entityName]="
      !!_customLegalEntity
        ? _customLegalEntity?.legalEntityDesc
        : !!_userProfile && _userProfile.userType !== 'C'
        ? _currentLegalEntity?.legalEntityDesc
        : null
    "
    [entityId]="
      !!_customLegalEntity
        ? _customLegalEntity?.legalEntityTypeCode
        : !!_userProfile && _userProfile.userType !== 'C'
        ? _currentLegalEntity?.legalEntityTypeCode
        : !!_customLegalEntity
        ? _customLegalEntity?.legalEntityTypeCode
        : null
    "
  >
    <ng-container
      *ngIf="
        !_isWaiting && !!!_legalEntityList?.length && !!_userProfile && _userProfile.userType !== 'C';
        else entityList
      "
    >
      <cwc-empty-result
        *ngIf="!_isWaiting && !!!_legalEntityList?.length"
        [icon]="
          !!!_legalEntityList?.length && _searchLegalEntity?.length >= minCharachterInput
            ? 'information-rounded-fill'
            : 'search'
        "
        class="empty-message fade-in"
        variant="flat"
        [header]="
          !!!_legalEntityList?.length && _searchLegalEntity?.length >= minCharachterInput
            ? translationService.pt('views.sidebar.entity-modal.empty-header')
            : null
        "
      >
        {{
          !!!_legalEntityList?.length && _searchLegalEntity?.length >= minCharachterInput
            ? translationService.pt('views.sidebar.entity-modal.empty-message')
            : null
        }}
      </cwc-empty-result>
    </ng-container>
    <div *ngIf="_isWaiting && !!_userProfile && _userProfile.userType !== 'C'" class="loader-content">
      <cwc-loader class="fade-in loader"></cwc-loader>
    </div>
    <ng-template #entityList>
      <cwc-entity-selector-item
        *ngFor="let legalEntity of _legalEntityList"
        attr.entity-name="{{ legalEntity?.legalEntityDesc }}"
        attr.entity-id="{{ legalEntity?.legalEntityTypeCode }}"
        [active]="!!_userProfile && _userProfile.userType !== 'C' ? null : isActiveLegalEntity(legalEntity)"
        class="entity-selector fade-in"
      ></cwc-entity-selector-item>
    </ng-template>
  </cwc-entity-selector>

  <cwc-sidenav-menu
    id="menuNav"
    slot="menu-items"
    *ngIf="!hiddenLegalEntity"
    [collapsed]="isCollapsed"
    [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
    class="collapsable"
  >
    <cwc-sidenav-menu-item
      *ngFor="let item of _menuItems"
      [id]="'cmx-sidebar-app-' + item?.applicationTitle"
      [label]="translationService.pt('views.sidebar.' + item?.applicationTitle)"
      [iconName]="item?.applicationIcon.replace('cmx-icon-', '')"
      [active]="item.menu.menuId == this.menuItemIdActive"
      data-id="cmx-sidebar-app-{{ item?.applicationTitle }}"
      (cwcClick)="onApplicationItemClick(item)"
      [collapsed]="isCollapsed"
      [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
    >
      <cwc-sidenav-menu-item
        *ngFor="let subitemData of item?.menu?.menuItems"
        [label]="translationService.pt('views.sidebar.' + subitemData?.menuTitle)"
        [active]="subitemData.menuId === this.subMenuItemIdActive"
        data-id="cmx-sidebar-app-subitem-{{ subitemData.menuTitle }}"
        (cwcClick)="onSubItemClick(subitemData)"
        [collapsed]="isCollapsed"
        [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
        [style.pointer-events]="noEvents ? 'none' : 'unset'"
      ></cwc-sidenav-menu-item>
    </cwc-sidenav-menu-item>
  </cwc-sidenav-menu>
  <cwc-footer-menu
    slot="links"
    [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
    class="collapsable sidenav__footer__width"
  >
    <cwc-footer-menu-item
      id="sidebarFooterButtonLegal"
      label="{{ translationService.pt('views.sidebar.legal') }}"
      (click)="legalClick()"
      [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
    ></cwc-footer-menu-item>
    <cwc-footer-menu-item
      id="sidebarFooterButtonPrivacy"
      label="{{
        translationService.pt('app.footer.privacy').indexOf('NOT') === -1
          ? translationService.pt('app.footer.privacy')
          : privacy
      }}"
      (click)="goToPrivacy()"
      [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
    >
    </cwc-footer-menu-item>
    <cwc-footer-menu-item
      id="sidebarFooterButtonCemex"
      label="cemex.com"
      href="https://www.cemex.com"
      target="_blank"
      [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
    ></cwc-footer-menu-item>
  </cwc-footer-menu>
  <div slot="copyright" (click)="copyrightClick()" class="collapsable sidenav__footer__width">
    <span *ngIf="isGetCopyright; else copyrightTranslationsV2" [innerHTML]="lblCopyright"> </span>
    <ng-template #copyrightTranslationsV2>
      <span>
        {{ translationService.pt('views.footer.copyright') }}
      </span>
    </ng-template>
  </div>
</cwc-sidenav>

<!-- mobile language selection -->
<div
  id="mobileLanguageSelection"
  [dir]="rtl ? 'rtl' : 'ltr'"
  *ngIf="_languageMenuActive"
  class="cmx-language mobile-only hidden"
>
  <div class="cmx-language__options">
    <div class="cmx-language__heading">{{ translationService.pt('views.sidebar.language.header') }}</div>
    <ul class="cmx-language__options-list">
      <li class="cmx-language__options-item" *ngFor="let language of _languages">
        <div class="cmx-form-field">
          <cmx-radio-button
            [rtl]="rtl"
            (change)="preSelectLanguage($event)"
            [value]="language.languageISO"
            [checked]="language.isSelected"
          >
            {{ language.languageName }}
          </cmx-radio-button>
        </div>
      </li>
    </ul>
  </div>
  <div class="cmx-language__footer">
    <button cmx-button color="support" (click)="closeLanguageSelection()">
      {{ translationService.pt('views.sidebar.language.cancel') }}
    </button>
    <button cmx-button (click)="changeLanguage()">{{ translationService.pt('views.sidebar.language.apply') }}</button>
  </div>
</div>

<div class="cmx-modal mobile-only" *ngIf="showAppNavigation">
  <div class="cmx-modal__container">
    <button id="appsNavigationDismiss" class="cmx-modal__close-button" (click)="toggleAppNavigation()">
      <span class="cmx-icon-close" aria-hidden="true"></span> <span class="sr-only">Close</span>
    </button>
    <div class="cmx-modal__section flex__item--fixed">
      <h2>CEMEX Apps</h2>
    </div>
    <div class="spacer-24"></div>
    <div class="cmx-modal__section flex__item--lose">
      <div class="cmx-console-menu__item--stack">
        <a class="cmx-console-menu__item-link" target="_blank" [href]="developerPortalUrl">
          <div class="cmx-console-menu__item-icon">
            <span class="cmx-icon-brakets cmx-console-menu__item-inner-icon" aria-hidden="true"></span>
          </div>
          <span class="cmx-console-menu__item-label">
            {{ translationService.pt('views.sidebar.developerCenter') }}
          </span>
        </a>
      </div>
    </div>
  </div>
  <!-- end cmx-modal__container -->
</div>

<!-- mobile legal entity selection-->
<div
  id="mobileLanguageSelection"
  [dir]="rtl ? 'rtl' : 'ltr'"
  *ngIf="_legalEntityMenuActive"
  class="cmx-language mobile-only hidden"
>
  <div class="cmx-language__options">
    <div class="cmx-language__heading-search">
      {{ translationService.pt('views.sidebar.customer.header') }}

      <div class="cmx-form-group mobile-only">
        <div class="cmx-form-control--icon-button-trail">
          <input
            type="text"
            class="cmx-form-control"
            id="name-field"
            [placeholder]="translationService.pt('views.sidebar.customer.search')"
            [(ngModel)]="_searchLegalEntity"
            (ngModelChange)="filterLegalEntities()"
          />
          <button class="cmx-button cmx-button--icon">
            <span class="cmx-icon-magnifier-glass" aria-hidden="true"></span> <span class="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>
    <ul class="cmx-language__options-list">
      <li
        class="cmx-language__options-item"
        [class.selected]="legalEntity.selected"
        (click)="preSelectLegalEntity(legalEntity)"
        *ngFor="let legalEntity of _legalEntityList | filterLegalEntity: _searchLegalEntity"
      >
        <div class="cmx-navbar__legal-entity-name">
          {{ legalEntity?.legalEntityDesc }}
        </div>
        <div class="cmx-navbar__legal-entity-id">#{{ legalEntity.legalEntityTypeCode }}</div>
      </li>
    </ul>
  </div>
  <div class="cmx-language__footer">
    <button cmx-button color="support" (click)="closeCustomerSelection()">
      {{ translationService.pt('views.sidebar.language.cancel') }}
    </button>
    <button cmx-button (click)="changeCustomer()">{{ translationService.pt('views.sidebar.language.apply') }}</button>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/';
import { Language } from '../../models';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { DefaultLanguage } from 'src/app/models/login';
import { LangTools } from '../../helpers/functions/lang.functions';

@Component({
  selector: 'language-selector',
  templateUrl: 'language-selector.component.html',
  styleUrls: ['language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  public lang: any = {};
  public langModalOpen = false;

  constructor(
    public translationService: TranslationService,
    public langTools: LangTools
  ) {
    // we set up the language at constructor
    this.lang = this.langTools.setUpLang(DefaultLanguage);
  }

  private $currentLanguage = new BehaviorSubject<Language>(null);

  @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input() rtl = false;

  @Input() set currentLanguage(value: Language) {
    this.$currentLanguage.next(value);
  }
  get currentLanguage(): Language {
    return this.$currentLanguage.getValue();
  }

  public languages: Language[];

  ngOnInit() {
    this.languages = window as { [key: string]: any }['CMX_LANGUAGES'];
    this.languages = this.languages['CMX_LANGUAGES'];

    this.translationService.languageLoaded.subscribe(() => {
      // we set up language once again when changes from the translation service arrive.
      this.lang = this.langTools.setUpLang(DefaultLanguage);
    });
  }

  public changeLanguage(language: Language) {
    this.$currentLanguage.next(language);
    this.languageChanged.emit(language.languageISO);
  }

  public langModalShow() {
    this.langModalOpen = true;
  }

  public langModalHide() {
    this.langModalOpen = false;
  }
}
